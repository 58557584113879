<template>
  <!--
    The view for the TriggerList-component.
    Used to display the triggers/problems.
  -->
  <Portlet 
    :title="$t('problemView.problems')" 
    icon="exclamation-triangle"
  >
    <template slot="buttons">
      <toggle-button
        v-model="autoRefresh"
        :labels="{ checked: 'auto-refresh', unchecked: 'no refresh' }"
        :width="115"
        :height="30"
        :font-size="12"
        class="m-0"
      />
    </template>
    <TriggerList
      ref="triggerListComponent"
      :auto-refresh="autoRefresh"
    />
  </Portlet>
</template>

<script>
export default {
  name: "Problem",
  components: {
    TriggerList: () => import('@/components/Problem/TriggerList.vue')
  },
  data () {
    return {
      autoRefresh: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('problemView.problems')
    }
  }
}
</script>
